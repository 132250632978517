import styled, { css } from 'styled-components'

import ROUTES from 'constants/routes'
import type { ThemeName } from 'styles/theme'

export const MenuButton = styled.div<{
	currentTheme: ThemeName
	isActive?: boolean
	isLink?: boolean
}>`
	display: flex;
	align-items: center;
	align-self: flex-start;
	width: 100%;
	font-size: 26px;
	line-height: 32px;
	margin-top: 20px;
	cursor: pointer;
	outline: none;
	text-transform: capitalize;
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.primary
			: props.theme.colors.selectedTheme.gray};
`

// color: ${window.location.pathname === ROUTES.Home.Root || props.currentTheme === 'dark'
// 				? props.theme.colors.common.primaryWhite
// 				: props.theme.colors.selectedTheme.black};
// 			path {
// 				${props.isLink ? 'fill' : 'stroke'}: ${window.location.pathname === ROUTES.Home.Root ||
// 				props.currentTheme === 'dark'
// 					? props.theme.colors.common.primaryWhite
// 					: props.theme.colors.selectedTheme.black};
// 			}
