import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { FlexDivCol } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'

import DocsIcon from 'assets/svg/social/docs.svg'
import CommunityIcon from 'assets/svg/social/community.svg'
import MoreIcon from 'assets/svg/app/link-new.svg'

import MetricsIcon from 'assets/svg/social/matric.svg'
import BlogIcon from 'assets/svg/social/blog.svg'
import TelegramIcon from 'assets/svg/social/telegram.svg'
import WalletIcon from 'assets/svg/social/wallet.svg'
import XIcon from 'assets/svg/social/x.svg'
import InstaIcon from 'assets/svg/social/instagram.svg'
import YoutubeIcon from 'assets/svg/social/youtube.svg'

const lists = [
	{ icon: <XIcon />, title: 'Twitter', href: EXTERNAL_LINKS.Social.Twitter, description: '' },
	{
		icon: <CommunityIcon />,
		title: 'Discord',
		href: EXTERNAL_LINKS.Social.Discord,
		description: '',
	},
	{
		icon: <TelegramIcon />,
		title: 'Telegram',
		href: EXTERNAL_LINKS.Social.telegram,
		description: '',
	},
	{ icon: <BlogIcon />, title: 'Blog', href: EXTERNAL_LINKS.Social.Blog, description: '' },
	{
		icon: <InstaIcon />,
		title: 'Instagram',
		href: EXTERNAL_LINKS.Social.Instagram,
		description: '',
	},
	{ icon: <YoutubeIcon />, title: 'Youtube', href: EXTERNAL_LINKS.Social.Youtube, description: '' },
	{
		icon: <DocsIcon />,
		title: 'Documentation',
		href: EXTERNAL_LINKS.Docs.Documentation,
		description: '',
	},
	{
		icon: <WalletIcon />,
		title: 'Smart Wallet',
		href: EXTERNAL_LINKS.Docs.SmartWallet,
		description: '',
	},
	{ icon: <MetricsIcon />, title: 'Metrics', href: EXTERNAL_LINKS.Docs.Metrics, description: '' },
]

const More: React.FC = () => {
	return (
		<Container>
			{lists.map((l, i) => (
				<Link href={l.href} key={i} target="_blank">
					<Icon>{l.icon}</Icon>
					<FlexDivCol rowGap="4px">
						<Title>
							{l.title}
							<span>
								<MoreIcon className="link-icon" />
							</span>
						</Title>
						<Description>{l.description}</Description>
					</FlexDivCol>
				</Link>
			))}
		</Container>
	)
}

export default More

const Container = styled.div`
	padding: 0;
	position: fixed;
	top: 0;
	left: 0;
	height: calc(100% - 68px);
	width: 100%;
	overflow-y: auto;
	z-index: 9999;
	background: ${(props) => props.theme.colors.selectedTheme.background};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};

	a {
		display: flex;
		align-items: center;
		height: 68px;
		padding: 0 28px;
		gap: 16px;
		border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	}

	svg {
		width: 20px;
		height: 20px;
		transition: all 0.15s ease-in-out 0s !important;
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.gray};
		}
	}

	.link-icon {
		path {
			fill: none;
		}
	}
`

// height: 92px;

const Icon = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	display: flex;
	align-items: center;
	font-size: 18px;
	line-height: 22px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};

	span {
		margin-top: 4px;
		margin-left: 6px;

		svg {
			width: 16px;
			height: 16px;
		}
	}
`

const Description = styled.div`
	font-size: 15px;
	line-height: 20px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
`
