import { PositionSide } from '@dextoroprotocol/sdk/types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import CloseIcon from 'assets/svg/app/close.svg'
import MenuIcon from 'assets/svg/app/menu.svg'
import Button from 'components/Button'
import MobileMenuModal from 'sections/shared/Layout/AppLayout/Header/MobileUserMenu/MobileMenuModal'
import MobileWalletButton from 'sections/shared/Layout/AppLayout/Header/MobileUserMenu/MobileWalletButton'

import { DEFAULT_FUTURES_MARGIN_TYPE } from 'constants/defaults'
import ROUTES from 'constants/routes'
import { zIndex } from 'constants/ui'
import { MOBILE_FOOTER_HEIGHT } from 'constants/ui'
import { setTradePanelDrawerOpen } from 'state/futures/reducer'
import { setLeverageSide } from 'state/futures/smartMargin/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectMobileTradePanel } from 'state/app/selectors'
import { FixedFooterMixin } from 'styles/common'
import MobileTradePanel from 'sections/futures/Trade/MobileTradePanel'
import { showMobileTradePanel } from 'state/app/reducer'
import MobileFooter from './MobileFooter'

const TradeFooter: React.FC = () => {
	return (
		<TradeFooterContainer>
			<PageTitle>Select Market</PageTitle>
		</TradeFooterContainer>
	)
}

const MobileUserMenu: FC = () => {
	const [isOpen, toggleOpen] = useReducer((s) => !s, false)
	const { t } = useTranslation()
	const { asPath } = useRouter()

	const dispatch = useAppDispatch()
	const selectShowMobileTradePanel = useAppSelector(selectMobileTradePanel)

	const handleSideSelect = useCallback(
		(side: PositionSide) => () => {
			dispatch(setLeverageSide(side))
			dispatch(setTradePanelDrawerOpen(true))
		},
		[dispatch]
	)

	return (
		<>
			<MobileFooterContainer>
				{!selectShowMobileTradePanel ? (
					<>
						<MobileFooterIconContainer onClick={toggleOpen}>
							{isOpen ? <CloseIcon height={25} width={25} /> : <MenuIcon height={25} width={25} />}
						</MobileFooterIconContainer>
						<LineStyle></LineStyle>
						<MobileFooterRight>
							{window.location.pathname === ROUTES.Home.Root ? (
								<Link href={ROUTES.Markets.Home(DEFAULT_FUTURES_MARGIN_TYPE)}>
									<Button size="small">{t('homepage.nav.start-trade')}</Button>
								</Link>
							) : asPath.split('/').includes('market') && !isOpen ? (
								<PositionButtonsContainer>
									<PositionButton
										data-testid="position-side-long-button"
										$position={PositionSide.LONG}
										$isActive={true}
										onClick={handleSideSelect(PositionSide.LONG)}
									>
										Buy
									</PositionButton>
									<PositionButton
										data-testid="position-side-short-button"
										$position={PositionSide.SHORT}
										$isActive={true}
										$right={true}
										onClick={handleSideSelect(PositionSide.SHORT)}
									>
										Sell
									</PositionButton>
								</PositionButtonsContainer>
							) : isOpen ? (
								<MobileFooter toggleModal={toggleOpen} />
							) : (
								<MobileWalletButton toggleModal={toggleOpen} />
							)}
						</MobileFooterRight>
					</>
				) : (
					<>
						<MobileFooterIconContainer
							onClick={() => dispatch(showMobileTradePanel(!selectShowMobileTradePanel))}
						>
							{selectShowMobileTradePanel ? (
								<CloseIcon height={25} width={25} />
							) : (
								<MenuIcon height={25} width={25} />
							)}
						</MobileFooterIconContainer>
						<LineStyle></LineStyle>
						<TradeFooter />
					</>
				)}
			</MobileFooterContainer>
			{isOpen && <MobileMenuModal onDismiss={toggleOpen} />}
			{selectShowMobileTradePanel && <MobileTradePanel />}
		</>
	)
}

type PositionButtonProps = {
	$position: PositionSide
	$isActive: boolean
	$right?: boolean
}

const MobileFooterContainer = styled.div`
	${FixedFooterMixin};
	display: flex;
	align-items: center;
	border-top: ${(props) => props.theme.colors.selectedTheme.border};
	padding: 15px 20px;
	background-color: ${(props) => props.theme.colors.selectedTheme.background};
	z-index: ${zIndex.MOBILE_FOOTER};
	height: ${MOBILE_FOOTER_HEIGHT};
`

const MobileFooterIconContainer = styled.div`
	width: 25px;
`

const MobileFooterRight = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	align-items: center;
`

const PositionButtonsContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
`
const PositionButton = styled.div<PositionButtonProps>`
	font-size: 12px;
	height: 40px;
	text-transform: uppercase;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	transition: color 0.2s ease-in-out;

	> span {
		position: relative;
	}

	${(props) => css`
		font-family: ${props.theme.fonts.bold};
		color: ${props.theme.colors.selectedTheme.newTheme.tabs.position.color};

		border-radius: 10px;
	`}

	${(props) =>
		props.$isActive &&
		css`
			color: ${props.theme.colors.selectedTheme.newTheme.tabs.position[props.$position].color};

			border: 2px ${props.theme.colors.selectedTheme.newTheme.tabs.position[props.$position].color}
				solid;
			background: ${props.theme.colors.selectedTheme.newTheme.tabs.position[props.$position]
				.background};
		`}
`
// font-variant: all-small-caps;

const LineStyle = styled.span`
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	width: 1px;
	margin: auto;
	height: 40px;
	margin-right: 20px;
	margin-left: 20px;
`

const TradeFooterContainer = styled.footer`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`

const PageTitle = styled.div`
	font-size: 20px;
	line-height: 24px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
`

export default MobileUserMenu
