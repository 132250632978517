import { FC, memo } from 'react'
import styled from 'styled-components'

import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import Banner from 'sections/shared/Layout/HomeLayout/Banner'
import Footer from 'sections/shared/Layout/AppLayout/Footer'
import Header from 'sections/shared/Layout/AppLayout/Header'
import MobileUserMenu from 'sections/shared/Layout/AppLayout/Header/MobileUserMenu'

import NotificationContainer from 'constants/NotificationContainer'
import { MobileScreenContainer } from 'styles/common'
import { useOneClickTradingData } from 'state/oneClickTrading/hooks'

type AppLayoutProps = {
	children: React.ReactNode
}

const AppLayout: FC<AppLayoutProps> = memo(({ children }) => {
	useOneClickTradingData()

	return (
		<AppLayoutContainer>
			<DesktopOnlyView>
				<DesktopGridContainer>
					<Header />
					<main>{children}</main>
					<Footer />
				</DesktopGridContainer>
			</DesktopOnlyView>
			<MobileOrTabletView>
				<MobileScreenContainer>
					<Banner />
					{children}
					<MobileUserMenu />
				</MobileScreenContainer>
			</MobileOrTabletView>
			<NotificationContainer />
		</AppLayoutContainer>
	)
})

const AppLayoutContainer = styled.div`
	height: 100%;

	> div {
		height: 100%;
	}
`

const DesktopGridContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template: auto 1fr auto / 100%;

	> main {
		display: flex;
		min-height: 0;
		width: 100%;
		overflow-y: auto;

		> div {
			width: 100%;
			height: 100%;
		}
	}
`

export default AppLayout
