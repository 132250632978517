import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import DropdownArrow from 'assets/svg/app/dropdown-arrow.svg'
import LangIcon from 'assets/svg/app/world-lang.svg'
import MainnetIcon from 'assets/svg/app/mannet-icon.svg'
import HelpMenuIcon from 'assets/svg/app/help-icon-menu.svg'
import MoreMenuIcon from 'assets/svg/app/more-dots.svg'

import LinkIconLight from 'assets/svg/app/link-light.svg'
import DextoroYellowIcon from 'assets/svg/brand/logo-yellow.svg'
import { FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import ROUTES from 'constants/routes'
import SmartMarginOnboardModal from 'sections/futures/Trade/SmartMarginOnboardModal'
import DarkLogo from 'assets/svg/brand/dextoro-logo.svg'
import LightLogo from 'assets/svg/brand/dextoro-logo-light.svg'
import { setOpenModal } from 'state/app/reducer'
import { selectShowModal } from 'state/app/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'

import { zIndex } from 'constants/ui'
import { HOMEPAGE_MENU_LINKS, MOBILE_NAV_LINKS } from '../constants'
import OperationStatus from '../../OperationStatus'

import { MenuButton } from './menu'
import MobileSubMenu from './MobileSubMenu'
import Help from './sections/Help'
import More from './sections/More'

type MobileMenuModalProps = {
	onDismiss(): void
}

export const MobileMenuModal: FC<MobileMenuModalProps> = ({ onDismiss }) => {
	const { t } = useTranslation()
	const router = useRouter()
	const dispatch = useAppDispatch()
	const openModal = useAppSelector(selectShowModal)
	const [subMenu, setSubMenu] = useState(
		router.asPath.includes('portfolio') && !router.asPath.includes('markets')
	)
	const [language, setLanguage] = useState<boolean | undefined>()
	const [mainnet, setMainnet] = useState<boolean | undefined>()
	const [helpMenu, setHelpMenu] = useState<boolean | undefined>()
	const [moreMenu, setMoreMenu] = useState<boolean | undefined>()

	const showSubMenu = useCallback(() => setSubMenu(!subMenu), [subMenu])
	const toggleLanguage = useCallback(() => {
		setLanguage(!language)
		setMainnet(false)
	}, [language])
	const toggleMainnet = useCallback(() => {
		setMainnet(!mainnet)
		setLanguage(false)
	}, [mainnet])
	const toggleHelpMenu = useCallback(() => setHelpMenu(!helpMenu), [helpMenu])
	const toggleMoreMenu = useCallback(() => setMoreMenu(!moreMenu), [moreMenu])

	const menuLinks =
		window.location.pathname === ROUTES.Home.Root ? HOMEPAGE_MENU_LINKS : MOBILE_NAV_LINKS

	const currentTheme = useAppSelector(selectCurrentTheme)

	const showStatsPage = useCallback(() => {
		router.push(ROUTES.Stats.Home)
		onDismiss()
	}, [router, onDismiss])

	const selectedThemeIndex = useMemo(() => (currentTheme === 'light' ? 0 : 1), [currentTheme])
	const DextoroLogo = useMemo(
		() => (selectedThemeIndex === 0 ? <DextoroLightLogo /> : <DextoroDarkLogo />),
		[selectedThemeIndex]
	)

	return (
		<MobileNav>
			<Container>
				<MetaRow>
					<Link href={ROUTES.Home.MainSite}>{DextoroLogo}</Link>
					<OperationStatus />
				</MetaRow>
				<LinkColumn>
					<Left>
						{menuLinks.map(({ i18nLabel, link, links }) => {
							const basePath1 = router.asPath.split('/')[1]
							const basePath2 = router.asPath.split('/')[2]
							const pathName =
								basePath1 === 'portfolio'
									? '/' + basePath1 + (basePath2 ? '/' + basePath2 : '')
									: basePath1

							const activeUrl =
								link.indexOf('/market') === 0
									? link.split('/')[1].split('?')[0]
									: link.indexOf('/portfolio') === 0
									? link
									: link.split('/')[1]
							const isActive =
								pathName === activeUrl ||
								(pathName === '/portfolio/history' && activeUrl === '/portfolio')

							return links?.length ? (
								<>
									<MenuButton currentTheme={currentTheme} isActive={isActive} onClick={showSubMenu}>
										{t(i18nLabel)} <StyledDropdownArrow width={12} height={12} isActive={subMenu} />
									</MenuButton>
									<MobileSubMenu
										links={links}
										i18nLabel={i18nLabel}
										// defaultOpen={router.asPath.includes(link)}
										onDismiss={onDismiss}
										showSubmenu={subMenu}
									/>
								</>
							) : link === ROUTES.Stats.Home ? (
								<MenuButton currentTheme={currentTheme} isActive={isActive} onClick={showStatsPage}>
									{t(i18nLabel)}
								</MenuButton>
							) : (
								<Link href={link}>
									<MenuButton
										currentTheme={currentTheme}
										isActive={isActive}
										onClick={onDismiss}
										isLink
									>
										<FlexDivRowCentered>
											{t(i18nLabel)}
											{i18nLabel === 'header.nav.markets' ? (
												<DextoroYellowIcon height={18} width={18} style={{ marginLeft: 6 }} />
											) : i18nLabel === 'header.nav.options.title' ? (
												<LinkIconLight height={16} width={16} style={{ marginLeft: 6 }} />
											) : null}
										</FlexDivRowCentered>
									</MenuButton>
								</Link>
							)
						})}
					</Left>
					<Right>
						<Circle onClick={toggleLanguage}>
							<LangIcon />
							{language && (
								<RightMenuContainer type="lang">
									<OptionContainer onClick={toggleLanguage}>
										<Options isActive>English</Options>
									</OptionContainer>
								</RightMenuContainer>
							)}
						</Circle>
						<Circle onClick={toggleMainnet}>
							<MainnetIcon />
							{mainnet && (
								<RightMenuContainer type="mainnet">
									<OptionContainer onClick={toggleMainnet}>
										<Options isActive>Mainnet</Options>
									</OptionContainer>
								</RightMenuContainer>
							)}
						</Circle>
						<Circle onClick={toggleHelpMenu}>
							<HelpMenuIcon />
						</Circle>
						<Circle onClick={toggleMoreMenu}>
							<MoreMenuIcon />
						</Circle>
					</Right>
				</LinkColumn>
			</Container>
			{openModal === 'futures_smart_margin_socket' && (
				<SmartMarginOnboardModal
					onDismiss={() => {
						dispatch(setOpenModal(null))
					}}
				/>
			)}
			{helpMenu && <Help />}
			{moreMenu && <More />}
		</MobileNav>
	)
}

const MobileNav = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	height: calc(100% - 68px);
	width: 100%;
	background: ${(props) => props.theme.colors.selectedTheme.background};
	overflow-y: auto;
	z-index: ${zIndex.DIALOG_OVERLAY};
`

const Container = styled.div`
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100%;
`

const DextoroDarkLogo = styled(DarkLogo)`
	width: 200px;
	height: 20px;
	margin-left: -40px;
`

const DextoroLightLogo = styled(LightLogo)`
	width: 200px;
	height: 20px;
	margin-left: -40px;
`

const MetaRow = styled(FlexDivRow)`
	display: flex;
	align-items: center;
	padding: 24px;
	position: sticky;
	top: 0;
	background-color: ${(props) => props.theme.colors.selectedTheme.background};
	box-shadow: 0 12px 12px ${(props) => props.theme.colors.selectedTheme.background};
	z-index: 1;
`

const LinkColumn = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	padding: 12px 28px 32px;
`

const Left = styled.div`
	display: grid;
	align-content: end;
`

const StyledDropdownArrow = styled(DropdownArrow)<{ isActive: boolean }>`
	margin-left: 4px;
	transform: ${(props) => (props.isActive ? 'rotate(180deg)' : 'rotate(360deg)')};
	transition: all 0.15s ease-in-out 0s !important;
	path {
		fill: currentColor;
	}
`

const Right = styled.div`
	display: grid;
	align-self: end;
	position: sticky;
	grid-gap: 16px;
	gap: 16px;
	bottom: 32px;
`

const Circle = styled.circle`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	background: ${(props) => props.theme.colors.selectedTheme.button.dextoroPrimary.background};

	svg {
		fill: ${(props) => props.theme.colors.selectedTheme.svgIcon.fill};
		transition: all 0.15s ease-in-out !important;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
`

const RightMenuContainer = styled.div<{ type: 'lang' | 'mainnet' }>`
	font-size: 16px;
	line-height: 20px;
	position: absolute;
	max-height: calc(100vh - 4px);
	overflow: hidden auto;
	margin: -32px 0;
	padding: 32px 0;
	right: 60px;
	bottom: ${(props) => (props.type === 'lang' ? `180px` : `120px`)};
`

const OptionContainer = styled.div`
	background-color: ${(props) => props.theme.colors.selectedTheme.gray3};
	border-radius: 6px;
	padding: 12px 24px 12px 20px;
`

const Options = styled.div<{ isActive?: boolean }>`
	display: flex;
	align-items: center;
	padding: 10px 0;
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.primary
			: props.theme.colors.selectedTheme.gray};
	cursor: pointer;
	white-space: nowrap;
`

export default MobileMenuModal
