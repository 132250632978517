import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useNetwork } from 'wagmi'
import styled from 'styled-components'

import HelpIcon from 'assets/svg/app/help.svg'
import Button from 'components/Button'
import Connector from 'containers/Connector'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'

import { useAutoConnect } from 'hooks/useAutoConnect'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setOpenModal } from 'state/app/reducer'
import { selectShowModal } from 'state/app/selectors'
import { selectSessionActivated } from 'state/oneClickTrading/selectors'
import useLocalStorage from 'hooks/useLocalStorage'

import Help from './Help'
import WalletActions from './WalletActions'
import BalanceActions from './BalanceActions'
import OneClickTrading from './OneClickTrading'

const WalletButtons: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { isWalletConnected } = Connector.useContainer()
	const { chain: network } = useNetwork()
	const { openConnectModal } = useConnectModal()
	const openModal = useAppSelector(selectShowModal)
	const isSessionActivated = useAppSelector(selectSessionActivated)
	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	useAutoConnect()

	const handleHelp = () => dispatch(setOpenModal('help'))

	const walletIsNotConnected = (
		<ItemContainer>
			<Items>
				<Box>
					<IconButton onClick={handleHelp}>
						<HelpIcon />
					</IconButton>
				</Box>
			</Items>
			<LineStyle />
			<ConnectButton
				size="xsmall"
				variant="flat"
				noOutline
				onClick={openConnect}
				data-testid="connect-wallet"
			>
				{t('common.wallet.connect-wallet')}
			</ConnectButton>
			{firstVisitModal && (
				<FirstSeenWalletConnectionModal
					onDismiss={closeFirstVisitModal}
					onConfirmed={visitModalConfirmed}
				></FirstSeenWalletConnectionModal>
			)}
		</ItemContainer>
	)

	const walletIsConnectedButNotSupported = (
		<ItemContainer>
			<Items>
				<Box>
					<IconButton onClick={handleHelp}>
						<HelpIcon />
					</IconButton>
				</Box>
			</Items>
			<LineStyle />
			<Button size="xsmall" variant="primary" data-testid="unsupported-network" mono>
				{t('common.wallet.unsupported-network')}
			</Button>
		</ItemContainer>
	)

	const walletIsConnectedAndSupported = (
		<ItemContainer>
			<BalanceActions />
			<LineStyle />
			<Items>
				<Box>
					<IconButton onClick={handleHelp}>
						<HelpIcon />
					</IconButton>
				</Box>
			</Items>
			<LineStyle />
			{isSessionActivated && (
				<>
					<OneClickTrading />
					<LineStyle />
				</>
			)}
			<WalletActions />
		</ItemContainer>
	)

	return (
		<Container>
			{isWalletConnected
				? network?.unsupported
					? walletIsConnectedButNotSupported
					: walletIsConnectedAndSupported
				: walletIsNotConnected}
			{openModal === 'help' && <Help />}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
`

const ItemContainer = styled.div`
	display: flex;
	align-items: center;
`

const Items = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Box = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	height: auto;
`

const IconButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	cursor: pointer;

	&:hover {
		border-radius: 8px;
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
			rect {
				stroke: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
		}
	}
`

const ConnectButton = styled(Button)`
	background: #6966ff;
	color: ${(props) => props.theme.colors.selectedTheme.button.dextoroPrimary.color};
	hover: {
		background: none;
	}
`

const LineStyle = styled.span`
	width: 1px;
	height: 24px;
	margin: 0 6px;
	background: ${(props) => props.theme.colors.selectedTheme.borderColor};
`

export default WalletButtons
