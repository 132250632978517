import { FC } from 'react'
import styled from 'styled-components'

import { FlexDivCol } from 'components/layout/flex'
import { MobileHiddenView } from 'components/Media'

import Banner from '../../HomeLayout/Banner'
import Logo from '../../Logo'
import Connector from 'containers/Connector'

import Nav from './Nav'
import WalletButtons from './WalletButtons'
type LogoNavProps = {
	hasWalletAddress?: boolean
}
const Header: FC = () => {
	const { walletAddress } = Connector.useContainer()
	return (
		<MobileHiddenView>
			<FlexDivCol>
				<Container>
					<LogoNav hasWalletAddress={!!walletAddress}>
						<BorderRightDiv>
							<Logo />
						</BorderRightDiv>
						<Nav />
					</LogoNav>
					<WalletButtons />
				</Container>
				<Banner />
			</FlexDivCol>
		</MobileHiddenView>
	)
}

const Container = styled.header`
	display: flex;
	justify-content: space-between;
	padding: 5px;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	max-height: 48px;
`
const BorderRightDiv = styled.div`
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
`

const LogoNav = styled.div<LogoNavProps>`
	display: flex;
	align-items: center;
	max-width: ${(props) => (props.hasWalletAddress ? 'calc(100% - 320px)' : 'calc(100% - 155px)')};
	nav {
		max-width: calc(100% - 45px);
	}
`
// max-width: calc(100% - 155px);

export default Header
