import { truncateAddress } from '@dextoroprotocol/sdk/utils'
import { FC, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useEnsAvatar, useEnsName } from 'wagmi'

import Button from 'components/Button'
import { FlexDivCentered } from 'components/layout/flex'
import Connector from 'containers/Connector'
import DropdownArrow from 'assets/svg/app/dropdown-arrow.svg'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectSettings } from 'state/app/selectors'
import { enableSettings } from 'state/app/reducer'
import { selectCurrentTheme } from 'state/preferences/selectors'
import Settings from './Settings'

export const WalletActions: FC = () => {
	const dispatch = useAppDispatch()
	const settings = useAppSelector(selectSettings)
	const { walletAddress } = Connector.useContainer()
	const { data: ensAvatar } = useEnsAvatar({ address: walletAddress!, chainId: 1 })
	const { data: ensName } = useEnsName({ address: walletAddress!, chainId: 1 })
	const currentTheme = useAppSelector(selectCurrentTheme)

	const truncatedWalletAddress = truncateAddress(walletAddress ?? '')
	const walletLabel = useMemo(() => {
		return ensName || truncatedWalletAddress!
	}, [ensName, truncatedWalletAddress])

	const handleMenu = useCallback(() => {
		dispatch(enableSettings(!settings))
	}, [dispatch, settings])

	return (
		<Container>
			<WalletButton
				size="small"
				variant="secondary"
				onClick={handleMenu}
				data-testid="connect-wallet"
				isName={!!ensName}
				expanded={settings}
				mono
				currentTheme={currentTheme}
			>
				<FlexDivCentered>
					{ensAvatar ? (
						<img
							src={ensAvatar}
							alt={ensName?.toString()}
							width={16}
							height={16}
							style={{ borderRadius: '50%', marginRight: '8px' }}
						/>
					) : (
						<ConnectedIcon>
							<ConnectionIndicator />
						</ConnectedIcon>
					)}
					<AddressBar>
						{walletLabel} <StyledDropdownArrow expanded={settings} />
					</AddressBar>
				</FlexDivCentered>
			</WalletButton>
			{settings && <Settings onDismiss={handleMenu} />}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 168px;
`

const WalletButton = styled(Button)<{ isName?: boolean; expanded?: boolean; currentTheme: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	background: ${(props) =>
		props.expanded ? props.theme.colors.selectedTheme.button.fill : 'none'};
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	width: 100%;
	height: 32px;
	min-height: 32px;
	border-radius: 8px;
	user-select: none;
	white-space: nowrap;
	color: ${(props) =>
		props.expanded
			? props.theme.colors.selectedTheme.primary
			: props.theme.colors.selectedTheme.gray};

	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.primary};
		background: ${(props) =>
			props.expanded
				? props.theme.colors.selectedTheme.button.fill
				: props.theme.colors.selectedTheme.button.fillHover};
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(1)')};
	}
`

const ConnectedIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: rgba(127, 212, 130, 0.2);
`

const ConnectionIndicator = styled.div`
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: ${(props) => props.theme.colors.common.primaryGreen};
`

const AddressBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1.4px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-feature-settings: 'zero' 0;

	svg {
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.gray};
		}
	}
	&:hover {
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
			}
		}
	}
`

const StyledDropdownArrow = styled(DropdownArrow)<{ expanded?: boolean }>`
	margin-left: 6px;
	margin-bottom: 2px;
	transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(360deg)')};
	transition: all 0.15s ease-in-out 0s !important;
`

export default WalletActions
